


<div *ngIf="spin" class="wrapper">
  <div class="inner"><mat-spinner></mat-spinner></div>
</div>

<div *ngIf="!spin">
<div  id="crd2" class="card mb-3" style="max-width: 94%;" *ngFor="let pl of productList">
  <div class="row no-gutters">
    <div class="col-md-4">
      

      <h5 style="color:dodgerblue;">{{pl.maker}} {{pl.model}}</h5>
      <p-galleria [(value)]="pl.img" [responsiveOptions]="responsiveOptions" [containerStyle]="{'max-width': '640px'}" [numVisible]="5"
          [circular]="true" [showItemNavigators]="true">
          <ng-template pTemplate="item" let-item>
              <img [src]="'https://sudosurf.herokuapp.com/'+item.filename" style="width:100%;height:250px" />
          </ng-template>
          <ng-template pTemplate="thumbnail" let-item>
              <div class="p-grid p-nogutter p-justify-center">
                  <img [src]="'https://sudosurf.herokuapp.com/'+item.filename"style="width:50%;height:50px"/>
              </div>
          </ng-template>
      </p-galleria>

<!-- 

      <div *ngFor="let pimg of pl.img;let i=index">
        
          <img class="card-img-top" [src]="'http://localhost:4800/'+pimg.filename" alt="Card image"
            style="width:100%;height:200px">
        
      </div> -->
    </div>
    <div class="col-md-8">
      <div class="card-body">
        <div class="row">
          <div class="col-md-4">
        <h5 class="card-title">{{pl.maker}} {{pl.model}}</h5>
        <p class="card-text"> Price :{{pl.price}}<br />
        Description :{{pl.Description.ram}}<br />
        Battery:{{pl.Description.battery}}<br />
      Display:{{pl.Description.display}}</p> </div>
    
      </div>
    </div>

  </div>
</div>
</div>
</div>

