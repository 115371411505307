import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { SurfservService } from '../surfserv.service';

@Component({
  selector: 'app-mobiles',
  templateUrl: './mobiles.component.html',
  styleUrls: ['./mobiles.component.css']
})
export class MobilesComponent implements OnInit {
  productList: any;
  productList1: any;
  image: any;
  visibleSidebar1: any;


  constructor(private router : Router, private acr: ActivatedRoute, private sss : SurfservService) { }
  GetDetails(id: any) { 
    window.open("details" + '/' + id);
  }
  ngOnInit(): void {
    this.sss.GetAllItems().subscribe((data)=>{this.productList = data;
      
    });
    this.sss.GetAllItems1().subscribe((data)=>{this.productList1 = data;
      
    });
  }

}
