import { product } from './model/product';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class SurfservService {
  url: string = "https://sudosurf.herokuapp.com/2";
  url0: string = "https://sudosurf.herokuapp.com/3";
  baseurl: string = "https://sudosurf.herokuapp.com";

  // url1: string = "http://localhost:4800/multipleFiles";


  constructor(private htp: HttpClient) { }
  
  GetAllItems(): Observable<any> {
    return this.htp.get(this.url,{responseType:'json'})
  }
  GetById(id: string): Observable<any> {
    return this.htp.get(this.baseurl+'/4/'+id,{responseType:'json'})
  }
  GetAllItems1(): Observable<any> {
    return this.htp.get(this.url0,{responseType:'json'})
  }
//   AddMembers(reg: product): Observable<any> {
//     const httpOptions = {
//         headers: new HttpHeaders({ 'content-type': 'application/json' })
//     }
//     return this.htp.post(this.url1 , JSON.stringify(reg), httpOptions)
// }
}

