
<div *ngIf="spin" class="wrapper">
  <div class="inner"><mat-spinner></mat-spinner></div>
</div>



<div class="container-fluid">
  <div class="col-md-12">
    <div class="row">
      <div class="col-md-1">
        Adv spacee and changes are reflecting
      </div>

      <div id="crd" class="col-md-11">
        <div class="row">

          <div class="card" style="width:300px" *ngFor="let pl of productList;let i=index">
            <ion-slide *ngIf="i<4">
              <div *ngFor="let pimg of pl.img;let i=index">
                <ion-slide *ngIf="i<1">
                  <img class="card-img-top" [src]="'https://sudosurf.herokuapp.com/'+pimg.filename" alt="Card image"
                    style="width:100%;height:150px">
                </ion-slide>
              </div>
              <div class="card-body">
                <h4 class="card-title">{{pl.maker}} {{pl.model}}</h4>
                <p>
                  Price :{{pl.price}}<br />
                  Description :{{pl.Description.ram}}<br />
                  Battery:{{pl.Description.battery}}
                </p>
                <a href="#" class="btn btn-info" (click)="GetDetails(pl._id)">More Details</a>
              </div>
            </ion-slide>
          </div>



        </div>
        <div id="crd2" class="card mb-3" style="max-width: 94%;" *ngFor="let pl of productList1">
          <div class="row no-gutters">
            <div class="col-md-4">
              <div *ngFor="let pimg of pl.img;let i=index">
                <ion-slide *ngIf="i<1">
                  <img class="card-img-top" [src]="'https://sudosurf.herokuapp.com/'+pimg.filename" alt="Card image"
                    style="width:100%;height:200px">
                </ion-slide>
              </div>
            </div>
            <div class="col-md-8">
              <div class="card-body">
                <div class="row">
                  <div class="col-md-4">
                <h5 class="card-title">{{pl.maker}} {{pl.model}}</h5>
                <p class="card-text"> Price :{{pl.price}}<br />
                Description :{{pl.Description.ram}}<br />
                Battery:{{pl.Description.battery}}<br />
              Display:{{pl.Description.display}}</p> </div>
              <div class="col-md-4">
                <button type="button" class="btn btn-info" (click)="GetDetails(pl._id)">More Details</button>
              
                          </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>

  </div>
</div>