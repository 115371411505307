import { SurfservService } from './../surfserv.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.css']
})
export class DetailsComponent implements OnInit {
  productList: any;
  spin: boolean = true;


  constructor(private router: Router, private acr: ActivatedRoute, private sss: SurfservService) {
    
   }

  ngOnInit(): void {
    let id = this.acr.snapshot.params.id;
    this.sss.GetById(id).subscribe((data)=>{this.productList = data;  
      this.spin = false;
    });
  
  }

}
