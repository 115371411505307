export class product{
    id: string;
    maker: string;
    model:string;
    price:string;
    camara: string;
    display: string;
    ram: string;
    battery: string;
    network: string;
    files: any;
    }