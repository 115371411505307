<nav class="navbar navbar-expand-sm bg-dark navbar-dark">
  <!-- Brand -->
  <a class="navbar-brand" routerLink="home">Surfbyt</a>

  <!-- Links -->
  <ul class="navbar-nav">
    <li class="nav-item">
      <a class="nav-link"  routerLink="mobile">Mobiles</a>
    </li>
    <li class="nav-item">
      <a class="nav-link"  routerLink="laptop">Laptops</a>
    </li>

    <!-- Dropdown -->
    <li class="nav-item dropdown">
      <a class="nav-link dropdown-toggle"  routerLink="home" id="navbardrop" data-toggle="dropdown">
        Accessories 
      </a>
      <div class="dropdown-menu">
        <a class="dropdown-item"  routerLink="home">Headphones</a>
        <a class="dropdown-item"  routerLink="home">Speakers</a>
        <a class="dropdown-item"  routerLink="home">Chargers and Cables</a>
      </div>
    </li>
  </ul>
</nav> 
<p-sidebar [(visible)]="visibleSidebar1" [baseZIndex]="10000">
  <h1 style="font-weight:normal">Surfbyt</h1>
  <div class="badges">
    <p-button label="Construction in Progress" icon="pi pi-palette" styleClass="p-button-warning" badge="8" badgeClass="p-badge-danger" ></p-button>
</div>
  <p-button type="button" (click)="visibleSidebar1 = false" label="Save" styleClass="p-button-info"></p-button>
  <p-button type="button" (click)="visibleSidebar1 = false" label="Cancel" styleClass="p-button-secondary"></p-button>
</p-sidebar>
<p-button type="button" (click)="visibleSidebar1 = true" icon="pi pi-arrow-right"></p-button>
<router-outlet></router-outlet>
