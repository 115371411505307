import { product } from './../model/product';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { SurfservService } from '../surfserv.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-sudo',
  templateUrl: './sudo.component.html',
  styleUrls: ['./sudo.component.css']
})
export class SudoComponent implements OnInit {
  title = 'fileUpload';
  images;
  multipleImages = [];
  product: product;
  constructor(private http: HttpClient, private sfs: SurfservService, private route: ActivatedRoute, private router: Router) {
    this.product = new product;
  }

  ngOnInit() {

  }

  selectImage(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.images = file;
    }
  }

  selectMultipleImage(event) {
    if (event.target.files.length > 0) {
      this.multipleImages = event.target.files;
    }
  }

  // onSubmit(){
  //   const formData = new FormData();
  //   formData.append('file', this.images);

  //   this.http.post<any>('http://localhost:4800/file', formData).subscribe(
  //     (res) => console.log(res),
  //     (err) => console.log(err)
  //   );
  // }


  onMultipleSubmit(sfrm: any) {
    const formData = new FormData();

    for (let img of this.multipleImages) {
      formData.append('files', img);
    }
    this.product.id = Math.random().toString(36).substr(2, 9);;
    formData.append('id', this.product.id);
    formData.append('maker', this.product.maker);
    formData.append('model', this.product.model);
    formData.append('ram', this.product.ram);
    formData.append('price', this.product.price);
    formData.append('battery', this.product.battery);
    formData.append('display', this.product.display);
    formData.append('camara', this.product.camara);
 
    this.http.post<any>('https://sudosurf.herokuapp.com/sudoaddproduct', formData).subscribe(
      (res) => console.log(res),
      (err) => console.log(err)
    );
  }

}
