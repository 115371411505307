<h1 style="text-align: center">Surfbyt</h1>
<div class="container">
  <form #sfrm="ngForm">
  <div class="row">
    <div class="col-md-6">
      <div class="form-group">
        <label for="exampleFormControlSelect1">Select Maker</label>
        <select class="form-control" [(ngModel)]="product.maker" name="maker" #maker id="maker">
          <option>Apple</option>
          <option>Samsung</option>
          <option>Oneplus</option>
          <option>Xiaomi</option>
          <option>Realme</option>
          <option>Asus</option>
          <option>Motorola</option>
          <option>Google</option>
          <option>Nokia</option>
        </select>
      </div>
      <div class="form-group">
        <label for="exampleInputEmail1">Price</label>
        <input type="email" class="form-control" [(ngModel)]="product.price" name="price" #price id="price"
          aria-describedby="price" placeholder="Enter price">
      </div>
      <div class="form-group">
        <label for="exampleInputEmail1">Display</label>
        <input type="email" class="form-control" [(ngModel)]="product.display" name="display" #display id="display"
          aria-describedby="" placeholder="Enter display details">
      </div>
      <div class="form-group">
        <label for="exampleInputEmail1">Battery</label>
        <input type="email" class="form-control" [(ngModel)]="product.battery" name="battery" #battery id="battery"
          aria-describedby="" placeholder="Enter battery">
      </div>
      <div class="form-group">
        <label for="exampleInputEmail1">Images</label>
        <input type="file" class="form-control" name="images" multiple (change)="selectMultipleImage($event)" />
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-group">
        <label for="exampleInputEmail1">Model</label>
        <input type="email" class="form-control" [(ngModel)]="product.model" name="model" #model id="model"
          aria-describedby="" placeholder="Enter model name">
      </div>
      <div class="form-group">
        <label for="exampleInputEmail1">Camara</label>
        <input type="email" class="form-control" [(ngModel)]="product.camara" name="camara" #camara id="camara"
          aria-describedby="" placeholder="Enter camara spec">
      </div>
      <div class="form-group">
        <label for="exampleInputEmail1">Ram</label>
        <input type="email" class="form-control" [(ngModel)]="product.ram" name="ram" #ram id="ram" aria-describedby=""
          placeholder="Enter ram size">
      </div>
      <div class="form-group">
        <label for="exampleInputEmail1">Network</label>
        <input type="email" class="form-control" [(ngModel)]="product.network" name="network" #network id="network"
          aria-describedby="" placeholder="Enter network specifications">
      </div>

    </div>
   
  </div>
  <div class="row justify-content-md-center">
    <button type="submit" (click)="onMultipleSubmit(sfrm)" class="btn btn-primary">Submit</button>
  </div>
</form>
</div>

<!-- <form>
  <div class="form-group">
    <label for="exampleFormControlSelect1">Select Maker</label>
    <select class="form-control" id="exampleFormControlSelect1">
      <option>Apple</option>
      <option>Samsung</option>
      <option>Oneplus</option>
      <option>Xiaomi</option>
      <option>Realme</option>
      <option>Asus</option>
      <option>Motorola</option>
      <option>Google</option>
      <option>Nokia</option>
    </select>
  </div>
  <div class="form-group">
    <label for="exampleInputEmail1">Model</label>
    <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email">
    <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small>
  </div>
  <div class="form-group">
    <label for="exampleInputEmail1">Price</label>
    <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email">
    <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small>
  </div>
  <div class="form-group">
    <label for="exampleInputEmail1">Camara</label>
    <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email">
    <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small>
  </div>
  <div class="form-group">
    <label for="exampleInputEmail1">Display</label>
    <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email">
    <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small>
  </div>
  <div class="form-group">
    <label for="exampleInputEmail1">Ram</label>
    <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email">
    <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small>
  </div>
  <div class="form-group">
    <label for="exampleInputEmail1">Battery</label>
    <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email">
    <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small>
  </div>
  <div class="form-group">
    <label for="exampleInputEmail1">network</label>
    <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email">
    <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small>
  </div>
 
  <div class="form-check">
    <input type="checkbox" class="form-check-input" id="exampleCheck1">
    <label class="form-check-label" for="exampleCheck1">Submit</label>
  </div>
  <button type="submit"  (click)="onMultipleSubmit()" class="btn btn-primary">Submit</button>
</form> -->





<!-- <div style="text-align: center">
  <form>      
    <div>
      <input type="file" name="image" (change)="selectImage($event)" />
    </div>
    <br>
    <div>
      <button type="submit" (click)="onSubmit()">Upload</button>
    </div>
  </form>
</div> -->

<!-- <hr>
<h2 style="text-align: center">Multiple</h2>
<div style="text-align: center">
  <form>      
    <div>
      <input type="file" name="images" multiple (change)="selectMultipleImage($event)" />
    </div>
    <br>
    <div>
      <button type="submit" (click)="onMultipleSubmit()">Multiple Upload</button>
    </div>
  </form>
</div> -->