import { SurfservService } from './../surfserv.service';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  productList: any;
  productList1: any;
  image: any;
  visibleSidebar1: any;
  spin: boolean = true;


  constructor(private router : Router, private acr: ActivatedRoute, private sss : SurfservService) { }

  GetDetails(id: any) { 
    window.open("details" + '/' + id);
  }
  ngOnInit(): void {
    this.sss.GetAllItems().subscribe((data)=>{this.productList = data;
      
    });
    this.sss.GetAllItems1().subscribe((data)=>{this.productList1 = data;
      this.spin = false;
    });

  }  

}
