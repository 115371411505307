import { Routes, RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { MobilesComponent } from './mobiles/mobiles.component';
import { LaptopsComponent } from './laptops/laptops.component';
import { AccessoriesComponent } from './accessories/accessories.component';
import { SudoComponent } from './sudo/sudo.component';
import { DetailsComponent } from './details/details.component';
import {GalleriaModule} from 'primeng/galleria';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {AccordionModule} from 'primeng/accordion';     //accordion and accordion tab
import { MenuItem } from 'primeng/api';                  //api
import { SidebarModule } from 'primeng/sidebar';
import { ButtonModule } from 'primeng/button';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner'; 


const routArray: Routes = [
  { path: "", component: HomeComponent },
  { path: "home", component: HomeComponent },
  { path: "sudo", component: SudoComponent },
  { path: "mobile", component: MobilesComponent },
  { path: "laptop", component: LaptopsComponent },
  { path: "details/:id", component: DetailsComponent },



]

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    MobilesComponent,
    LaptopsComponent,
    AccessoriesComponent,
    SudoComponent,
    DetailsComponent,
    
  ],
  imports: [
    MatProgressSpinnerModule,
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    HttpClientModule,
    AccordionModule,
    GalleriaModule,
    ButtonModule,
    SidebarModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(routArray),

  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
